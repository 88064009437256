var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accept-goods-container"
  }, [_vm.goods.accept_other ? _c('van-cell', {
    attrs: {
      "value-class": "accept-tips",
      "title": "提示：",
      "value": "此商品为验收时收到的其它退货，非配送员输入的退货"
    }
  }) : _vm._e(), _vm.goods.remark ? _c('van-cell', [_c('template', {
    slot: "title"
  }, [_c('span', [_vm._v("退货备注：")]), _c('span', {
    staticClass: "remark"
  }, [_vm._v(_vm._s(_vm.goods.remark))])])], 2) : _vm._e(), _vm.goods.accept_remark ? _c('van-cell', [_c('template', {
    slot: "title"
  }, [_c('span', [_vm._v("验收备注：")]), _c('span', {
    staticClass: "remark"
  }, [_vm._v(_vm._s(_vm.goods.accept_remark))])])], 2) : _vm._e(), _vm.goods.accept_image ? _c('van-cell', {
    attrs: {
      "title": "验收图片",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.handleImagePreview
    }
  }) : _vm._e(), _c('div', {
    staticClass: "accept-goods-container__content"
  }, [_vm.goods.accept_finished_datetime ? _c('div', {
    staticClass: "accept-goods-container__finished-logo"
  }, [_c('van-image', {
    attrs: {
      "width": "1.78667rem",
      "height": "1.6rem",
      "src": require('@/assets/images/yiyanshou.png')
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "accept-goods-container__content-img"
  }, [_c('van-image', {
    attrs: {
      "width": "2.26666rem",
      "height": "2.26666rem",
      "src": _vm.goods.image
    }
  })], 1), _c('div', {
    staticClass: "accept-goods-container__content-text"
  }, [_c('div', {
    staticClass: "accept-goods-container__content-name"
  }, [_vm._v(_vm._s(_vm.goods.name))]), _c('div', {
    staticClass: "accept-goods-container__size-storage"
  }, [_c('span', [_vm._v("规格：1*" + _vm._s(_vm.goods.size))])]), _c('div', {
    staticClass: "accept-goods-container__large-least"
  }, [_vm.goods.good_volume > 0 ? _c('div', [_vm._v(" 完好的： "), _c('van-tag', {
    attrs: {
      "type": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.goods.good_volume) + _vm._s(_vm.goods.least_unit) + " ")])], 1) : _vm._e(), _vm.goods.bad_volume > 0 ? _c('div', [_vm._v(" 损坏的： "), _c('van-tag', {
    attrs: {
      "type": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.goods.bad_volume) + _vm._s(_vm.goods.least_unit) + " ")])], 1) : _vm._e()])])]), _vm.goods.accept_finished_datetime ? _c('div', {
    staticClass: "accpet-finished"
  }, [_c('van-cell', {
    attrs: {
      "title": "已验收完好的",
      "value": _vm.goods.accept_good_volume + _vm.goods.least_unit
    }
  }), _c('van-cell', {
    attrs: {
      "title": "已验收损坏的",
      "value": _vm.goods.accept_bad_volume + _vm.goods.least_unit
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "accpet-btns"
  }, [!_vm.isFinished ? _c('van-button', {
    attrs: {
      "square": "",
      "type": "info",
      "block": "",
      "size": "small"
    },
    on: {
      "click": _vm.handleAccept
    }
  }, [_vm._v("确认验收")]) : _vm._e(), !_vm.isFinished && _vm.goods.accept_other ? _c('van-button', {
    attrs: {
      "block": "",
      "square": "",
      "type": "danger",
      "size": "small"
    },
    on: {
      "click": _vm.handleRemove
    }
  }, [_vm._v("移除")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }