<template>
  <div class="accept-goods-container">
    <van-cell v-if="goods.accept_other" value-class="accept-tips" title="提示：" value="此商品为验收时收到的其它退货，非配送员输入的退货" />
    <van-cell v-if="goods.remark">
      <template slot="title">
        <span>退货备注：</span>
        <span class="remark">{{ goods.remark }}</span>
      </template>
    </van-cell>
    <van-cell v-if="goods.accept_remark">
      <template slot="title">
        <span>验收备注：</span>
        <span class="remark">{{ goods.accept_remark }}</span>
      </template>
    </van-cell>
    <van-cell
      v-if="goods.accept_image"
      title="验收图片"
      is-link
      center
      @click="handleImagePreview"
    />
    <div class="accept-goods-container__content">
      <div
        v-if="goods.accept_finished_datetime"
        class="accept-goods-container__finished-logo"
      >
        <van-image
          width="1.78667rem"
          height="1.6rem"
          :src="require('@/assets/images/yiyanshou.png')"
        />
      </div>
      <div class="accept-goods-container__content-img">
        <van-image width="2.26666rem" height="2.26666rem" :src="goods.image" />
      </div>
      <div class="accept-goods-container__content-text">
        <div class="accept-goods-container__content-name">{{ goods.name }}</div>
        <div class="accept-goods-container__size-storage">
          <span>规格：1*{{ goods.size }}</span>
        </div>
        <div class="accept-goods-container__large-least">
          <div v-if="goods.good_volume > 0">
            完好的：
            <van-tag type="success">
              {{ goods.good_volume }}{{ goods.least_unit }}
            </van-tag>
          </div>
          <div v-if="goods.bad_volume > 0">
            损坏的：
            <van-tag type="danger">
              {{ goods.bad_volume }}{{ goods.least_unit }}
            </van-tag>
          </div>
        </div>
      </div>
    </div>
    <div v-if="goods.accept_finished_datetime" class="accpet-finished">
      <van-cell title="已验收完好的" :value="goods.accept_good_volume + goods.least_unit" />
      <van-cell title="已验收损坏的" :value="goods.accept_bad_volume + goods.least_unit" />
    </div>
    <div class="accpet-btns">
      <van-button v-if="!isFinished" square type="info" block size="small" @click="handleAccept">确认验收</van-button>
      <van-button v-if="!isFinished && goods.accept_other" block square type="danger" size="small" @click="handleRemove">移除</van-button>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
  name: 'AcceptGoods',
  props: {
    goods: {
      type: Object,
      required: true
    },
    isFinished: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleAccept() {
      this.$emit('accept-goods', this.goods)
    },
    handleRemove() {
      this.$emit('remove-other-goods', this.goods)
    },
    handleImagePreview() {
      ImagePreview([this.goods.accept_image])
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/ellipsis";
@import "src/styles/word-break";
@import "src/styles/color";
.accept-goods-container {
  overflow: hidden;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(48, 48, 48, 0.07);
  &__content {
    position: relative;
    display: flex;
    padding: 15px;
  }
  &__large-least {
    color: #a3a3a3;
    font-size: 13px;
  }
  &__content-text {
    @extend %ellipsis;
    padding: 0 10px;
  }
  &__content-name {
    @extend %word-break;
    color: #1c1c1c;
  }
  &__size-storage {
    color: #a3a3a3;
    font-size: 13px;
  }
  &__accept-info {
    padding: 0 15px 15px 15px;
  }
  &__accept-button {
    text-align: right;
  }
  &__finished-logo {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__tag {
    margin-left: 10px;
  }
}

.turn-receipt {
  margin-right: 5px;
}
.finished-large-least {
  color: #a3a3a3;
  font-size: 13px;
}
.accpet-finished {
  position: relative;
}
.accpet-finished::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 15px;
    top: 0;
    left: 15px;
    border-bottom: 1px solid #ebedf0;
    transform: scaleY(.5);
}
.accept-tips {
  flex: 5;
  text-align: left;
}
.accpet-btns {
  display: flex;
}
.remark {
  color: $amountRedColor;
}
</style>
