var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accept-detail-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "验收明细",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": _vm.is_finished ? '' : '取消验收'
    },
    on: {
      "click-right": function clickRight($event) {
        _vm.is_finished ? function () {} : _vm.cancel();
      }
    }
  }), !_vm.is_finished ? _c('div', {
    staticClass: "filter-container"
  }, [_c('van-cell', {
    attrs: {
      "title": "收到其它退货",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.toGoodsReturnView
    }
  }), _c('van-cell', {
    attrs: {
      "title": "上传退货凭证",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.handleUploadImages
    }
  })], 1) : _c('div', {
    staticClass: "filter-container"
  }, [_c('van-cell', {
    attrs: {
      "title": "查看退货凭证",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.handleShowImages
    }
  })], 1), _c('div', {
    class: !_vm.is_finished ? 'main-container' : 'main-container-finished'
  }, [_c('div', {
    class: !_vm.is_finished ? 'accept-detail-container__content' : 'accept-detail-container__finished-content'
  }, _vm._l(_vm.goodses, function (agoods, index) {
    return _c('accept-goods', {
      key: index,
      attrs: {
        "goods": agoods,
        "is-finished": _vm.is_finished
      },
      on: {
        "accept-goods": _vm.handleDialogShow,
        "remove-other-goods": _vm.handleRemoveOther
      }
    });
  }), 1)]), _vm.goodses.length > 0 && !_vm.is_finished ? _c('div', {
    staticClass: "fixed-bottom-btn accept-detail-container__footer"
  }, [_c('van-button', {
    attrs: {
      "disabled": _vm.notAcceptCount > 0,
      "square": "",
      "block": ""
    },
    on: {
      "click": _vm.finished
    }
  }, [_vm.notAcceptCount === 0 ? _c('span', [_vm._v("验收完成")]) : _c('span', [_vm._v("还有" + _vm._s(_vm.notAcceptCount) + "种商品未验收")])])], 1) : _vm._e(), _c('van-dialog', {
    attrs: {
      "title": "输入验收数量",
      "show-cancel-button": "",
      "confirm-button-text": "确定",
      "confirm-button-color": "#1A6FF2"
    },
    on: {
      "confirm": _vm.acceptGoods
    },
    model: {
      value: _vm.showInputDialog,
      callback: function callback($$v) {
        _vm.showInputDialog = $$v;
      },
      expression: "showInputDialog"
    }
  }, [_c('div', {
    staticClass: "accept-detail-container__input"
  }, [_c('van-cell', {
    staticStyle: {
      "color": "#646566"
    },
    attrs: {
      "title": "转成收条"
    }
  }, [_c('van-switch', {
    attrs: {
      "size": "20px"
    },
    model: {
      value: _vm.isReceipt,
      callback: function callback($$v) {
        _vm.isReceipt = $$v;
      },
      expression: "isReceipt"
    }
  })], 1), _vm.goods ? _c('van-field', {
    attrs: {
      "type": "number",
      "label": "完好的",
      "placeholder": "请输入完好的数量"
    },
    model: {
      value: _vm.acceptGoodVolume,
      callback: function callback($$v) {
        _vm.acceptGoodVolume = $$v;
      },
      expression: "acceptGoodVolume"
    }
  }) : _vm._e(), _vm.goods ? _c('van-field', {
    attrs: {
      "type": "number",
      "label": "损坏的",
      "placeholder": "请输入损坏的数量"
    },
    model: {
      value: _vm.acceptBadVolume,
      callback: function callback($$v) {
        _vm.acceptBadVolume = $$v;
      },
      expression: "acceptBadVolume"
    }
  }) : _vm._e(), _c('van-field', {
    attrs: {
      "label": "备注",
      "placeholder": "请输入备注(选填)"
    },
    model: {
      value: _vm.remark,
      callback: function callback($$v) {
        _vm.remark = $$v;
      },
      expression: "remark"
    }
  })], 1)]), _c('van-dialog', {
    attrs: {
      "show-cancel-button": "",
      "show-confirm-button": !_vm.is_finished,
      "title": !_vm.is_finished ? '上传退货凭证' : '查看退货凭证'
    },
    on: {
      "confirm": _vm.handleAcceptImages
    },
    model: {
      value: _vm.showImages,
      callback: function callback($$v) {
        _vm.showImages = $$v;
      },
      expression: "showImages"
    }
  }, [_c('div', {
    staticClass: "uploader-container"
  }, [_c('van-uploader', {
    attrs: {
      "disabled": _vm.is_finished,
      "max-count": "6",
      "before-read": _vm.beforeRead,
      "after-read": _vm.afterRead,
      "capture": "camera",
      "deletable": !_vm.is_finished
    },
    on: {
      "delete": _vm.deleteImage
    },
    model: {
      value: _vm.files,
      callback: function callback($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }