<template>
  <div class="accept-detail-container">
    <my-nav-bar
      title="验收明细"
      left-text="返回"
      left-arrow
      :right-text="is_finished ? '' : '取消验收'"
      @click-right="is_finished ? () => {} : cancel()"
    />
    <div v-if="!is_finished" class="filter-container">
      <van-cell title="收到其它退货" is-link center @click="toGoodsReturnView" />
      <van-cell title="上传退货凭证" is-link center @click="handleUploadImages" />
    </div>
    <div v-else class="filter-container">
      <van-cell title="查看退货凭证" is-link center @click="handleShowImages" />
    </div>
    <div :class="!is_finished ? 'main-container' : 'main-container-finished'">
      <div :class="!is_finished ? 'accept-detail-container__content' : 'accept-detail-container__finished-content'">
        <accept-goods
          v-for="(agoods, index) in goodses"
          :key="index"
          :goods="agoods"
          :is-finished="is_finished"
          @accept-goods="handleDialogShow"
          @remove-other-goods="handleRemoveOther"
        />
      </div>
    </div>
    <div v-if="goodses.length > 0 && !is_finished" class="fixed-bottom-btn accept-detail-container__footer">
      <van-button :disabled="notAcceptCount > 0" square block @click="finished">
        <span v-if="notAcceptCount === 0">验收完成</span>
        <span v-else>还有{{ notAcceptCount }}种商品未验收</span>
      </van-button>
    </div>
    <van-dialog
      v-model="showInputDialog"
      title="输入验收数量"
      show-cancel-button
      confirm-button-text="确定"
      confirm-button-color="#1A6FF2"
      @confirm="acceptGoods"
    >
      <div class="accept-detail-container__input">
        <van-cell title="转成收条" style="color: #646566">
          <van-switch v-model="isReceipt" size="20px" />
        </van-cell>
        <van-field v-if="goods" v-model="acceptGoodVolume" type="number" label="完好的" placeholder="请输入完好的数量" />
        <van-field v-if="goods" v-model="acceptBadVolume" type="number" label="损坏的" placeholder="请输入损坏的数量" />
        <!-- <van-cell title="上传图片" style="color: #646566">
          <van-uploader v-model="files" max-count="1" :before-read="beforeRead" capture="camera" @delete="deleteImage" />
        </van-cell> -->
        <van-field v-model="remark" label="备注" placeholder="请输入备注(选填)" />
      </div>
    </van-dialog>

    <van-dialog
      v-model="showImages"
      show-cancel-button
      :show-confirm-button="!is_finished"
      :title="!is_finished ? '上传退货凭证' : '查看退货凭证'"
      @confirm="handleAcceptImages"
    >
      <div class="uploader-container">
        <van-uploader
          v-model="files"
          :disabled="is_finished"
          max-count="6"
          :before-read="beforeRead"
          :after-read="afterRead"
          capture="camera"
          :deletable="!is_finished"
          @delete="deleteImage"
        />
      </div>
    </van-dialog>

  </div>
</template>

<script>
import afterAcceptApi, { uploadImage, changeAcceptImages, removeOtherGoods } from '@/api/after_accept'
import AcceptGoods from './components/goods'
import { now, sortByKey } from '@/utils'
import MyNavBar from '@/components/my-nav-bar'
import Compressor from 'compressorjs'
export default {
  name: 'AcceptDetail',
  components: { AcceptGoods, MyNavBar },
  data() {
    return {
      is_finished: Number(this.$route.query.is_finished) === 1,
      goodses: [],
      showInputDialog: false,
      goods: null,
      type: null,
      remark: '',
      isReceipt: false,
      files: [],
      uploadFiles: [],
      acceptGoodVolume: '',
      acceptBadVolume: '',
      acceptImages: [],
      showImages: false
    }
  },
  computed: {
    notAcceptCount() {
      let notAcceptCount = this.goodses.length
      this.goodses.map(goods => {
        if (goods.accept_finished_datetime) { notAcceptCount-- }
      })
      return notAcceptCount
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.beginLoad()
      const params = {
        order_id: this.$route.query.order_id
      }
      afterAcceptApi.detail(params).then(res => {
        this.endLoad()
        this.goodses = res.data.goods_list
        res.data.accept_images.forEach(url => {
          this.files.push({
            status: 'success',
            url
          })
          this.acceptImages.push(url)
          this.uploadFiles.push({ url })
        })
        if (res.data.accept_id !== this.$store.state.userInfo.id) {
          this.is_finished = true
        }
      })
    },
    cancel() {
      this.confirm('确定取消验收吗？').then(() => {
        const data = {
          order_id: this.$route.query.order_id
        }
        this.beginLoad()
        afterAcceptApi.cancel(data).then(res => {
          this.success(res.msg)
          this.$router.go(-1)
        })
      })
    },
    handleDialogShow(goods) {
      this.showInputDialog = true
      this.goods = Object.assign({}, goods)
      this.acceptGoodVolume = goods.good_volume
      this.acceptBadVolume = goods.bad_volume
      this.remark = ''
      this.isReceipt = Boolean(goods.is_receipt)
    },
    beforeRead(file) {
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          convertTypes: ['image/png', 'image/webp'],
          convertSize: 100000,
          quality: 0.2,
          success: (result) => {
            const newFile = new File([result], file.name, { type: file.type })
            resolve(newFile)
          },
          error(err) {
            console.log(err.message)
          }
        })
      })
    },
    afterRead(fileObj) {
      this.beginLoad()
      uploadImage(fileObj.file).then(res => {
        this.endLoad()
        this.acceptImages.push(res.data.image)
        this.uploadFiles.push(fileObj)
      })
    },
    deleteImage(fileObj) {
      const index = this.uploadFiles.findIndex(file => {
        if (file.content) {
          return file.content === fileObj.content
        } else {
          return file.url === fileObj.url
        }
      })
      console.log(index)
      if (index !== -1) {
        this.acceptImages.splice(index, 1)
        this.uploadFiles.splice(index, 1)
      }
    },
    acceptGoods() {
      const data = {
        order_id: this.$route.query.order_id,
        after_goods_id: this.goods.id,
        accept_good_volume: this.acceptGoodVolume ? this.acceptGoodVolume : 0,
        accept_bad_volume: this.acceptBadVolume ? this.acceptBadVolume : 0,
        accept_remark: this.remark,
        is_receipt: Number(this.isReceipt)
      }
      this.beginLoad()
      afterAcceptApi.goods(data).then(res => {
        this.success(res.msg)
        const goods = this.goodses.find(item => item.id === this.goods.id)
        goods.accept_good_volume = this.acceptGoodVolume
        goods.accept_bad_volume = this.acceptBadVolume
        goods.accept_image = this.goods.accept_image
        goods.is_receipt = data.is_receipt
        const needSort = !goods.accept_finished_datetime
        goods.accept_finished_datetime = now()
        if (needSort) {
          // 将已验收的移到最后面
          this.goodses.sort(sortByKey('accept_finished_datetime', 'asc'))
        }
      })
    },
    finished() {
      // 检测验收数量是否完整
      const hasGoodsList = this.goodses.filter(goods => {
        return Number(goods.good_volume) + Number(goods.bad_volume) !== Number(goods.accept_good_volume) + Number(goods.accept_bad_volume)
      })
      if (hasGoodsList.length > 0) {
        this.confirm('系统检测到退货商品验收数量不完整，请确认是否有收到其它退货', '提示', {
          confirmButtonColor: '#1989fa',
          confirmButtonText: '录入其它退货',
          showCancelButton: true,
          cancelButtonText: '确认完成'
        }).then(() => {
          this.toGoodsReturnView()
        }).catch(() => {
          setTimeout(() => {
            this.confirm('确认完成将不可撤销，要继续完成操作吗？', '警告').then(() => {
              const data = {
                order_id: this.$route.query.order_id
              }
              this.beginLoad()
              afterAcceptApi.finish(data).then(res => {
                this.success(res.msg)
                this.$eventBus.$emit('acceptFinished')
                this.$router.go(-1)
              })
            })
          }, 500)
        })
      } else {
        this.confirm('确认完成将不可撤销，要继续完成操作吗？', '警告').then(() => {
          const data = {
            order_id: this.$route.query.order_id
          }
          this.beginLoad()
          afterAcceptApi.finish(data).then(res => {
            this.success(res.msg)
            this.$eventBus.$emit('acceptFinished')
            this.$router.go(-1)
          })
        })
      }
    },
    toGoodsReturnView() {
      this.$router.push({
        path: 'goods-return-add',
        query: {
          client_id: this.$route.query.client_id,
          after_order_id: this.$route.query.order_id,
          is_other: 1
        }
      })
    },
    handleUploadImages() {
      this.showImages = true
    },
    handleShowImages() {
      this.showImages = true
    },
    handleAcceptImages() {
      this.beginLoad()
      const data = {
        after_order_id: this.$route.query.order_id,
        accept_images: this.acceptImages
      }
      changeAcceptImages(data).then(() => {
        this.endLoad()
      })
    },
    handleRemoveOther(goods) {
      this.confirm('确定要移除商品吗？', '提示').then(() => {
        const data = {
          after_order_id: goods.after_order_id,
          after_goods_id: goods.id
        }
        this.beginLoad()
        removeOtherGoods(data).then(res => {
          this.success(res.msg)
          setTimeout(() => {
            this.getDetail()
          }, 500)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 46px;
    z-index: 9;
  }
  .main-container {
    margin-top: 134px;
  }
  .main-container-finished {
    margin-top: 90px;
  }
  .accept-detail-container {
    &__content {
      padding: 15px 15px 5px 15px;
      margin-bottom: 50px;
    }
    &__finished-content {
      padding: 15px 15px 5px 15px;
    }
    &__footer {
      background-color: #fff;
      z-index: 99;
      .van-button {
        height: 50px;
      }
    }
  }
  .uploader-container {
    padding: 28px;
  }
</style>
